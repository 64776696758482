const EditorialDisclosure = ({ className, dataCy }: { className?: string; dataCy?: string }) => (
  <p
    className={className}
    data-cy={dataCy}
  >
    EDITORIAL DISCLOSURE
    <em>: The advice, opinions, or rankings contained in this article are solely those of the Fortune Recommends</em>™
    <em>
      {' '}
      editorial team. This content has not been reviewed or endorsed by any of our affiliate partners or other third
      parties.
    </em>
  </p>
);

export default EditorialDisclosure;
