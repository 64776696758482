/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getBodyStyle, getColor } from 'styles/theme';
import { arrowRight, arrowLeft } from 'styles/icons';

export const Wrapper = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const List = styled.ol`
  display: flex;
  gap: 8px;

  @media (${breakpoint.lgMax}) {
    display: none;
  }

  &.tablet-and-mobile {
    @media (${breakpoint.lgMin}) {
      display: none;
    }

    @media (${breakpoint.lgMax}) {
      display: flex;
    }
  }
`;

const arrowSizes = css`
  border-width: 0 2px 2px 0;
  padding: 4.5px;
  margin-top: 5px;
`;

const simpleArrow = css<{ theme: Theme }>`
  ${arrowLeft}
  ${arrowSizes}
  margin-left: 5px;

  @media (${breakpoint.lgMin}) {
    ${arrowRight}
    ${arrowSizes}
    margin-right: 5px;
    margin-left: 0;
  }
`;

export const CrumbWrapper = styled.li<{ theme: Theme }>`
  display: flex;
  gap: 8px;
  ${getBodyStyle('graphikCond', { default: 'small' }, 'semiBold')};

  &:last-child:not(:only-child) {
    position: relative;
    margin-left: 8px;

    // adding double arrow to the last element on desktop view
    &::after {
      position: absolute;
      content: '';
      ${simpleArrow}
      color: ${getColor('neutralDarkGray')};
      left: -8px;
    }
  }

  &::before {
    content: '';
    ${simpleArrow}
    color:  ${getColor('neutralDarkGray')};
    align-self: flex-start;
  }

  // hide first arrow on desktop
  &:first-child:not(:only-child)::before {
    display: none;
  }

`;

interface CrumbProps {
  $last?: boolean;
  theme: Theme;
}

export const Crumb = styled.span<CrumbProps>`
  text-transform: uppercase;
  line-height: 21px;
  letter-spacing: 0.5px;
  ${(props) =>
    (!props.$last ?
      css`
        transition: all 0.25s;
        white-space: nowrap;
      ` :
      css<{ theme: Theme }>`
        color: ${getColor('textSecondary')};;
        text-align: left;
      `)}
`;
