import { useEffect, useState } from 'react';
import useBreakpoint from 'hooks/useBreakpoint';
import * as S from './PaginationArrowNumericStyle';

export interface PaginationArrowNumericProps {
  totalCount: number;
  currentPage: number;
  recordsPerPage: number;
  onPageChange: (value: number) => void;
  className?: string;
  trackEventFunction?: (arrowName: string) => void;
}

export const paginate = (records: any[], page: number, limit: number) => {
  const startIndex = (page - 1) * limit;
  const endIndex = page * limit;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return records.slice(startIndex, endIndex);
};

const convertPage = (currentPage: number, fromItemsPerPage: number, toItemsPerPage: number): number => {
  // keeps the first item from the current page on the new page
  const firstItemIndex = (currentPage - 1) * fromItemsPerPage;
  const newPage = Math.floor(firstItemIndex / toItemsPerPage) + 1;
  return newPage;
};

const NonVisiblePagesEllipsis = () => (
  <li>
    <span aria-label='Ellipsis for non-visible pages'>...</span>
  </li>
);

const PageNum = ({
  pageNumber,
  onClick,
  selected,
  key,
}: {
  pageNumber: number;
  onClick: (pageNumber: number) => void;
  selected: boolean;
  key?: number;
}) => (
  <li>
    <S.PageNum
      key={key}
      onClick={() => {
        onClick(pageNumber);
      }}
      $selected={selected}
      data-cy={`page-${pageNumber}`}
      aria-label={`Go to page ${pageNumber}`}
    >
      {pageNumber}
    </S.PageNum>
  </li>
);

const PaginationArrowNumeric = ({
  totalCount,
  recordsPerPage,
  currentPage,
  className,
  onPageChange,
  trackEventFunction,
}: PaginationArrowNumericProps) => {
  const [pageNum, setPageNum] = useState<number>(currentPage);
  const [currentRecordsPerPage, setCurrentRecordsPerPage] = useState<number>(recordsPerPage);
  const isMobile = useBreakpoint('mdMax');

  useEffect(() => {
    setPageNum(currentPage);
  }, [currentPage]);

  useEffect(() => {
    // the number of records per page changes from desktop to mobile view
    if (recordsPerPage !== currentRecordsPerPage) {
      const updatedPage = convertPage(pageNum, currentRecordsPerPage, recordsPerPage);
      onPageChange(updatedPage);
      setCurrentRecordsPerPage(recordsPerPage);
    }
  }, [recordsPerPage]);

  // number of pages displayed, besides the current page (left + right side)
  const noOfPagesToShow = isMobile ? 2 : 4;

  const totalPageCount = Math.ceil(totalCount / currentRecordsPerPage) || 1;

  const hasHiddenPages = totalPageCount > noOfPagesToShow + 2;

  const pagesDisplayedArray = !hasHiddenPages ?
    Array.from({ length: totalPageCount }, (_, i) => i + 1) :
    Array.from(
      { length: noOfPagesToShow + 1 },
      (_, i) => Math.min(Math.max(pageNum - noOfPagesToShow / 2, 1), totalPageCount - noOfPagesToShow) + i,
    );

  const firstPageDisplayed = pagesDisplayedArray[0];
  const lastPageDisplayed = pagesDisplayedArray[pagesDisplayedArray.length - 1];

  return (
    <S.PaginationWrapper className={className}>
      <S.Arrow
        type='button'
        aria-label='Go to previous page'
        className='previous'
        disabled={pageNum <= 1}
        onClick={() => {
          if (trackEventFunction) trackEventFunction('Previous');
          onPageChange(pageNum - 1);
        }}
        data-cy='previousPage'
      >
        <S.PreviousArrow />
      </S.Arrow>
      <S.PageNumbersWrapper>
        {!isMobile && hasHiddenPages && firstPageDisplayed > 1 && (
          <>
            <PageNum
              pageNumber={1}
              selected={pageNum === 1}
              onClick={(no) => {
                if (trackEventFunction) trackEventFunction(`Page ${no}`);
                onPageChange(no);
              }}
            />
            {firstPageDisplayed > 2 && <NonVisiblePagesEllipsis />}
          </>
        )}
        {pagesDisplayedArray.map((pageN0) => (
          <PageNum
            key={pageN0}
            pageNumber={pageN0}
            selected={pageN0 === pageNum}
            onClick={(no) => {
              if (trackEventFunction) trackEventFunction(`Page ${no}`);
              onPageChange(no);
            }}
          />
        ))}
        {!isMobile && hasHiddenPages && lastPageDisplayed < totalPageCount && (
          <>
            {lastPageDisplayed < totalPageCount - 1 && <NonVisiblePagesEllipsis />}
            <PageNum
              pageNumber={totalPageCount}
              selected={pageNum === totalPageCount}
              onClick={(no) => {
                if (trackEventFunction) trackEventFunction(`Page ${no}`);
                onPageChange(no);
              }}
            />
          </>
        )}
      </S.PageNumbersWrapper>
      <S.Arrow
        type='button'
        aria-label='Go to next page'
        className='next'
        disabled={pageNum >= totalPageCount}
        onClick={() => {
          if (trackEventFunction) trackEventFunction('Next');
          onPageChange(pageNum + 1);
        }}
        data-cy='nextPage'
      >
        <S.NextArrow />
      </S.Arrow>
    </S.PaginationWrapper>
  );
};
export default PaginationArrowNumeric;
