import styled, { css } from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getBodyStyle, getColor, Theme } from 'styles/theme';
import { arrowRight, arrowLeft } from 'styles/icons';

const arrow = css<{ theme: Theme }>`
  padding: 4.5px;
  border-color: ${getColor('iconPrimary')};
  margin-bottom: 1px;
  border-width: 0 2px 2px 0;

  &:hover,
  &:focus {
    border-color: ${getColor('iconAccentPrimary')};
  }
  &:active {
    border-color: ${getColor('iconSecondary')};
  }
`;
export const PreviousArrow = styled.span<{ theme: Theme }>`
  ${arrowLeft}
  ${arrow}
`;

export const NextArrow = styled.span<{ theme: Theme }>`
  ${arrowRight}
  ${arrow}
`;

export const PaginationWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  margin: 16px 24px 0 24px;

  @media (${breakpoint.mdMin}) {
    margin-bottom: 16px;
  }
`;

export const Arrow = styled.button<{ theme: Theme }>`
  &:disabled {
    cursor: auto;
    span {
      border-color: ${getColor('textSecondary')};
    }
  }
`;

export const PageNumbersWrapper = styled.ul<{ theme: Theme }>`
  display: flex;
  gap: 24px;
  list-style-type: none!important;
  padding-inline-start: 0 !important;
  margin: 0!important;
  
  li {
    margin-bottom: 0;
  }
`;

export const PageNum = styled.button<{ theme: Theme; $selected: boolean }>`
  ${getBodyStyle('graphikCond', { default: 'default' }, 'semiBold')};
  ${({ $selected }) => $selected && css<{ theme: Theme }>`color: ${getColor('textAccentPrimary')}`};
  &:hover{
    color: ${getColor('textAccentPrimary')};
    text-decoration: underline;
  }
`;
