import { useEffect, useMemo, useState } from 'react';
import { layoutCount } from 'constants/constants';
import useBreakpoint from 'hooks/useBreakpoint';
import SpinningLoader from 'components/Loader/SpinningLoader';
import PaginationArrowNumeric, { paginate } from 'components/Pagination/PaginationArrowNumeric';
import { formatDate } from 'utils/dateUtils';
import { trackEvent } from 'services/Gtm/functions';
import { APIParameters } from 'api/RedVentures/common';
import { CardInfo, OutputsInfo } from 'interfaces/RecommendsWidget';
import { EventData } from 'services/Gtm/entities';
import { Wrapper, HeaderContent, InfoBar, SavingsAccountRatesWrapper, NoResultsMessage, Title } from './WidgetStyles';
import SavingsAccountRatesCard from './RatesCard';
import { InfoItemTooltips, LegalSection } from './WidgetElements';
import WidgetInput, { FilterBy, RecommendsWidgetInputType } from './WidgetInput';

export interface RecommendsWidgetProps {
  widgetTitle: string;
  className?: string;
  userZipCode: string;
  inputs: RecommendsWidgetInputType[];
  outputs: OutputsInfo;
  getDataFunc: (params: APIParameters) => Promise<CardInfo[]>;
  type: string;
  trackingData: EventData;
  postId: number;
}

const RecommendsWidget = ({
  widgetTitle,
  className,
  userZipCode,
  inputs,
  outputs,
  getDataFunc = () => Promise.resolve([] as CardInfo[]),
  type,
  trackingData,
  postId,
}: RecommendsWidgetProps) => {
  const isMobile = useBreakpoint('mdMax');

  const [depAmount, setDepositAmount] = useState<number>(25000);
  const [savAccRates, setSavAccRates] = useState<CardInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [error, setError] = useState<boolean>(false);

  const getData = ({ accountType, depositAmount, zipCode, minTermLength, maxTermLength }: FilterBy) => {
    if (zipCode) {
      setIsLoading(true);
      setError(false);
      getDataFunc({
        depositAmount,
        prodType: accountType.split(','),
        productTerm: Array.from({ length: maxTermLength - minTermLength + 1 }, (__, i) => i + minTermLength),
        zip: zipCode,
      })
        .then((data: CardInfo[]) => {
          setSavAccRates(data);
        })
        .catch(() => {
          setSavAccRates([]);
          setError(true);
          trackEvent({
            contentPlacementCD: 'Recommends Widget',
            eventAction: 'API Error',
            eventCategory: 'Recommends Widget',
            ...trackingData,
          });
        })
        .finally(() => {
          setPageNumber(1);
          setIsLoading(false);
          if (depAmount !== depositAmount) setDepositAmount(depositAmount || 0);
        });
    }
  };

  const onInputChange = (filters: FilterBy) => {
    getData(filters);
  };

  useEffect(() => {
    const impressionData = {
      eventAction: 'impression',
      eventCategory: 'Recommends widget',
      ...trackingData,
    };
    trackEvent(impressionData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recordsPerPage = useMemo(
    () => (isMobile ? layoutCount.newSavingsWidgetMobile : layoutCount.newSavingsWidgetDesktop),
    [isMobile],
  );

  return (
    <Wrapper
      className={`${className} savingsWidgetWrapper`}
      data-cy={type}
    >
      <header>
        {!isMobile && (
          <LegalSection
            disclosureAndPolicy
            poweredBy
          />
        )}
        {isMobile && <LegalSection disclosureAndPolicy />}
        <HeaderContent>
          <Title
            as='h4'
            $fontFamily='graphikCond'
            $size={{ default: 'level2' }}
          >
            {widgetTitle} {formatDate('MMMM D, YYYY')}
          </Title>
        </HeaderContent>
      </header>
      <WidgetInput
        userZipCode={userZipCode}
        inputs={inputs}
        onChange={(newFilters) => onInputChange(newFilters)}
        trackingData={trackingData}
      />
      {!isMobile && (
        <InfoBar $type={type}>
          {Object.values(outputs).map(({ title, info, placement }) => (
            <InfoItemTooltips
              key={title}
              title={title}
              info={info.replace('$DEPOSIT_AMOUNT$', depAmount.toString())}
              position={placement || 'bottom-right'}
            />
          ))}
        </InfoBar>
      )}
      <div className='loader'>
        <SpinningLoader show={isLoading} />
      </div>
      <SavingsAccountRatesWrapper>
        {savAccRates.length > 0 ? (
          <>
            {paginate(savAccRates, pageNumber, recordsPerPage).map((card: CardInfo, idx) => (
              <SavingsAccountRatesCard
                // using indexes as keys because the records don't have any unique identifier (nextUrl is not necessarily unique)
                // eslint-disable-next-line react/no-array-index-key
                key={`${card.nextUrl}${idx}`}
                data={card}
                outputs={outputs}
                depositAmount={depAmount}
                trackingData={trackingData}
                type={type}
                postId={postId}
              />
            ))}
          </>
        ) : (
          <>
            {!isLoading && !error && (
              <NoResultsMessage data-cy='noResultsFound'>No Results Found, try changing your filters</NoResultsMessage>
            )}
            {!isLoading && error && (
              <NoResultsMessage data-cy='errorMessage'>An error occurred, try changing your filters</NoResultsMessage>
            )}
          </>
        )}
      </SavingsAccountRatesWrapper>
      <PaginationArrowNumeric
        className='pagination'
        totalCount={savAccRates.length}
        recordsPerPage={recordsPerPage}
        currentPage={pageNumber}
        onPageChange={(num) => setPageNumber(num)}
        trackEventFunction={(arrowName) =>
          trackEvent({
            contentPlacementCD: 'Recommends Widget',
            eventAction: `${arrowName} pagination click`,
            eventCategory: 'Recommends Widget',
            ...trackingData,
          })
        }
      />
      {isMobile && (
        <LegalSection
          poweredBy
          noBackground
        />
      )}
    </Wrapper>
  );
};

export default RecommendsWidget;
