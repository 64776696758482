import { ReactElement, useContext } from 'react';
import { embedComponentsIntoContent } from 'utils/miscUtils/childrenUtils';
import { InStreamList } from 'components/Ad/AdSlot';
import { UserPaywallContext } from 'providers/PaywallProvider';
import { PageType } from 'interfaces/content/articles/Post';

const ContentWithEmbed = ({
  children,
  embedInstructions,
  adFrequency,
  lastPWithoutAds,
  pageType,
}: {
  children: ReactElement | ReactElement[];
  embedInstructions: { node: string; index: number }[];
  adFrequency: number;
  lastPWithoutAds?: number;
  pageType?: PageType;
}) => {
  const { paywallCheck, regwallCheck } = useContext(UserPaywallContext).paywallState;

  const renderAds = paywallCheck === 'no-paywall' && !(regwallCheck || adFrequency === 0);

  const content = Array.isArray(children) ?
    embedComponentsIntoContent(children[children.length - 1], children.slice(0, -1), embedInstructions) :
    embedComponentsIntoContent(children, [], embedInstructions);

  return (
    <InStreamList
      frequency={adFrequency}
      lastPWithoutAds={lastPWithoutAds}
      renderAds={renderAds}
      pageType={pageType}
    >
      {content}
    </InStreamList>
  );
};

export default ContentWithEmbed;
