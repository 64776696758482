import InfoToggletip from 'components/InfoToggletip';
import { BankrateLogo } from 'icons/BankrateLogo';
import Image from 'next/image';
import { useRef, useState } from 'react';
import { isUrl } from 'utils/stringUtils';
import RawHtml from 'utils/miscUtils/rawHtml';
import Modal from 'components/Modal';
import Rating from 'components/Rating';
import { trackEvent } from 'services/Gtm/functions';
import { EventData } from 'services/Gtm/entities';
import Link from 'components/Globals/Base/Link';
import * as S from './WidgetStyles';

export const LegalSection = ({
  noBackground,
  disclosureAndPolicy,
  poweredBy,
}: {
  noBackground?: boolean;
  disclosureAndPolicy?: boolean;
  poweredBy?: boolean;
}) => {
  const [isDisclosureOpened, setIsDisclosureOpened] = useState<boolean>(false);
  const dialogButton = useRef<HTMLButtonElement>(null);
  return (
    <S.Legal
      data-cy='legal'
      $noBackground={noBackground}
    >
      {disclosureAndPolicy && (
        <span>
          <button
            type='button'
            aria-label='Open Advertiser Disclosure Pop-Up'
            ref={dialogButton}
            onClick={() => {
              setIsDisclosureOpened(true);
            }}
          >
            Advertiser Disclosure
          </button>
          <Modal
            isOpened={isDisclosureOpened}
            title='Advertiser Disclosure'
            onClose={() => {
              setIsDisclosureOpened(false);
              if (dialogButton.current) dialogButton.current.focus();
            }}
            firstButton={{
              onClick: () => {
                setIsDisclosureOpened(false);
                if (dialogButton.current) dialogButton.current.focus();
              },
              title: 'CLOSE',
            }}
          >
            <S.AdvDisclosureContent>
              <p>
                The rate information is obtained by Bankrate from the listed institutions. Bankrate cannot guarantee the
                accuracy or availability of any rates shown. Institutions may have different rates on their own websites
                than those posted on Bankrate.com. The listings that appear on this page are from companies from which
                this website receives compensation, which may impact how, where, and in what order products appear,
                except where prohibited by law for our mortgage, home equity and other home lending products. This table
                does not include all companies or all available products.
              </p>
              <p>
                All rates are subject to change without notice and may vary depending on location. These quotes are from
                banks, thrifts, and credit unions, some of whom have paid for a link to their own website where you can
                find additional information. Those with a paid link are our Advertisers. Those without a paid link are
                listings we obtain to improve the consumer shopping experience and are not Advertisers. To receive the
                Bankrate.com rate from an Advertiser, please identify yourself as a Bankrate customer. Bank and thrift
                deposits are insured by the Federal Deposit Insurance Corp. Credit union deposits are insured by the
                National Credit Union Administration.
              </p>
              <p>
                Consumer Satisfaction: Bankrate attempts to verify the accuracy and availability of its
                Advertisers&apos; terms through its quality assurance process and requires Advertisers to agree to our
                Terms and Conditions and to adhere to our Quality Control Program. If you believe that you have received
                an inaccurate quote or are otherwise not satisfied with the services provided to you by the institution
                you choose, please{' '}
                <a
                  href='https://www.bankrate.com/contact/'
                  target='_blank'
                  // eslint-disable-next-line react/no-invalid-html-attribute
                  rel='nofollow noopener noreferrer'
                >
                  click here
                </a>
                .
              </p>
              <p>
                For details on our best products and how we chose them{' '}
                <a
                  href='https://www.bankrate.com/banking/savings/rates/'
                  target='_blank'
                  // eslint-disable-next-line react/no-invalid-html-attribute
                  rel='nofollow noopener noreferrer'
                >
                  click here
                </a>
                .
              </p>
            </S.AdvDisclosureContent>
          </Modal>
          <Link
            href='https://www.bankrate.com/privacy/'
            target='_blank'
            // eslint-disable-next-line react/no-invalid-html-attribute
            rel='nofollow noopener noreferrer'
            type='white'
          >
            Privacy policy
          </Link>
        </span>
      )}
      {poweredBy && (
        <S.PoweredBy
          href='https://www.bankrate.com/'
          target='_blank'
          rel='nofollow noopener noreferrer'
        >
          Powered by {RawHtml({ html: BankrateLogo })}
        </S.PoweredBy>
      )}
    </S.Legal>
  );
};

export const InfoItemTooltips = ({
  title,
  info,
  position,
  className,
}: {
  title: string;
  info: string;
  position?: string;
  className?: string;
}) => (
  <S.InfoItemWrapper
    className={className}
    data-cy={`${title}-Label`}
  >
    {title}
    {info && (
      <InfoToggletip
        content={info}
        position={position}
        dataCy={`${title}-Toggletip`}
      />
    )}
  </S.InfoItemWrapper>
);

export const InfoItemValues = ({
  title,
  info,
  titleLabel,
  infoLabel,
  dataCy,
}: {
  title: string;
  info: string;
  titleLabel: string;
  infoLabel: string;
  dataCy?: string;
}) => (
  <S.SavingRateInfoItem data-cy={dataCy}>
    {title && <span aria-label={`${titleLabel} ${title}`}>{title}</span>}
    {info && (
      <p
        aria-label={`${infoLabel} ${info}`}
        title={infoLabel}
      >
        {info}
      </p>
    )}
  </S.SavingRateInfoItem>
);

export const InstitutionImage = ({ image, name }: { image: string; name: string }) => {
  const [err, setErr] = useState(false);
  return (
    <div
      className='institutionImage'
      data-cy='institutionImage'
    >
      <span>
        {image && !err ? (
          <Image
            src={isUrl(image) ? image : `https://www.brimg.net/system/img/inst/${image}`}
            alt={`${name} image`}
            width='119'
            height='76'
            onError={() => {
              setErr(true);
            }}
            data-cy='widgetImage'
          />
        ) : (
          <S.ImagePlaceholder
            aria-label='Institution'
            data-cy='imagePlaceholder'
          >
            {name}
          </S.ImagePlaceholder>
        )}
      </span>
    </div>
  );
};

export const InstitutionInfo = ({
  rating,
  productName,
  institutionName,
  isMobile,
  insuranceDisclosure,
}: {
  rating: number;
  productName: string;
  institutionName: string;
  isMobile?: boolean;
  insuranceDisclosure?: string;
}) => (
  <div
    className='institutionInfo'
    data-cy='institutionInfo'
  >
    <div>
      <Rating score={rating || 0} />
      <InfoToggletip
        content='Bankrate scores are objectively determined by our editorial team. Our scoring formula weighs several factors consumers should consider when choosing financial products and services.'
        position={isMobile ? 'bottom-left' : 'bottom-right'}
        dataCy='widgetBankrateToggletip'
      />
    </div>
    <S.ProductName data-cy='productName'>
      {productName.charAt(0).toUpperCase() + productName.slice(1)} Account
    </S.ProductName>
    <S.InstitutionName>{institutionName}</S.InstitutionName>
    {insuranceDisclosure && <S.InsuranceDisclosure>{insuranceDisclosure}</S.InsuranceDisclosure>}
  </div>
);

export const PromotedOffer = ({ isPaid, advertiserNote }: { isPaid: boolean; advertiserNote: string }) => (
  <S.PromotedOfferWrapper data-cy='promotedOffer'>
    {isPaid && <div className='promotedTag'>Promoted Offer</div>}
    {advertiserNote && <div className='advNote'>{RawHtml({ html: advertiserNote })}</div>}
  </S.PromotedOfferWrapper>
);

export const OfferingInfoSection = ({
  nextUrl,
  offerDetails,
  reviewLink,
  institutionName,
  trackingData,
}: {
  nextUrl: string;
  offerDetails: string;
  reviewLink: string;
  institutionName: string;
  trackingData: EventData;
}) => {
  const [showOfferInfo, setShowOfferInfo] = useState<boolean>(false);
  return (
    <>
      <S.NextSection>
        {nextUrl && (
          <S.OpenAccountButton
            href={nextUrl}
            target='_blank'
            rel='nofollow noopener noreferrer'
            type='primaryButton'
            hasRightArrow={false}
            onClick={() =>
              trackEvent({
                contentPlacementCD: 'Recommends Widget',
                eventAction: `${institutionName} click`,
                eventCategory: 'Recommends Widget',
                ...trackingData,
              })
            }
            data-cy='openAccountButton'
          >
            OPEN ACCOUNT
          </S.OpenAccountButton>
        )}
        {offerDetails && (
          <S.ShowHide
            role='button'
            aria-label={`${showOfferInfo ? 'Hide' : 'Show'} offer details`}
            onClick={() => {
              setShowOfferInfo(!showOfferInfo);
            }}
            className={showOfferInfo ? 'opened' : ''}
            data-cy='offerDetails'
          >
            OFFER DETAILS
          </S.ShowHide>
        )}
      </S.NextSection>
      <S.OfferInfo
        $show={!!(offerDetails && showOfferInfo)}
        data-cy='offerInfo'
      >
        QUICK LOOK <br />
        {RawHtml({ html: offerDetails })}
        {reviewLink && (
          <div className='readBankReview'>
            <a
              href={reviewLink}
              target='_blank'
              // eslint-disable-next-line react/no-invalid-html-attribute
              rel='nofollow noopener noreferrer'
            >
              READ BANK REVIEW
            </a>
          </div>
        )}
      </S.OfferInfo>
    </>
  );
};

export const Logo = ({ siteLogo, className, label }: { siteLogo: string; className: string; label: string }) => (
  <div
    className={className}
    aria-label={label}
  >
    {RawHtml({ html: siteLogo })}
  </div>
);
