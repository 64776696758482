import getCDRates from 'api/RedVentures/getCDRates';
import getSavingsMMA from 'api/RedVentures/getSavingsMMA';
import { defaultZipCode } from 'api/RedVentures/getUserZipCode';
import { SavingsWidget, CDRatesWidget } from 'components/RecommendsWidget';
import React, { ReactElement, useEffect, useState } from 'react';
import { EventData } from 'services/Gtm/entities';
import { trackEvent } from 'services/Gtm/functions';
import { MicrositeType, RedVentureWidgets } from 'constants/constants';

interface WidgetsConfig {
  id: string;
  widget: React.ComponentType<any>;
  enabled?: boolean;
  getDataFn: (...args: any[]) => any;
  show: boolean;
  widgetName: 'Savings' | 'CD';
}

const RVWidgetsConfig: { [microsite: MicrositeType]: WidgetsConfig[] } = {
  recommends: [
    {
      enabled: process.env.FEATURE_FLAG_NEW_SAVINGS_WIDGET,
      getDataFn: getSavingsMMA,
      id: RedVentureWidgets.SAVINGS_MMA,
      show: false,
      widget: SavingsWidget,
      widgetName: 'Savings',
    },
    {
      enabled: process.env.FEATURE_FLAG_NEW_CD_RATES_WIDGET,
      getDataFn: getCDRates,
      id: RedVentureWidgets.CD_RATES,
      show: false,
      widget: CDRatesWidget,
      widgetName: 'CD',
    },
  ],
};

function embedRVWidgets(
  elem: ReactElement,
  widgetsConfig: WidgetsConfig[],
  zipCode: string,
  trackingData: EventData,
  postId: number,
): ReactElement {
  let currentIndex = 0;
  function processElement(element: ReactElement): ReactElement {
    if (!React.isValidElement(element)) return element;
    const props = element.props as { [key: string]: any };
    const nodeWidgetConfig = widgetsConfig.find((widget) => widget.id === props['data-ad-id']);
    if (nodeWidgetConfig && nodeWidgetConfig.enabled && nodeWidgetConfig.show) {
      currentIndex += 1;
      return (
        <nodeWidgetConfig.widget
          getDataFunc={nodeWidgetConfig.getDataFn}
          userZipCode={zipCode}
          trackingData={{ ...trackingData, eventLabel: `${nodeWidgetConfig.widgetName} - ${currentIndex}` }}
          postId={postId}
        />
      );
    }
    const children = React.Children.map(props.children as ReactElement[], (child: ReactElement) =>
      processElement(child));
    return React.cloneElement(element, props, children);
  }
  return processElement(elem);
}

const testRVAPIs = (zipCode: string, ids: string[], trackingData: EventData) =>
  Promise.all(
    [
      {
        id: RedVentureWidgets.CD_RATES,
        req: () =>
          getCDRates({
            depositAmount: 0,
            page: 1,
            pageSize: 1,
            productTerm: [1],
            zip: zipCode,
          }),
      },
      {
        id: RedVentureWidgets.SAVINGS_MMA,
        req: () =>
          getSavingsMMA({
            depositAmount: 0,
            page: 1,
            pageSize: 1,
            prodType: ['SAVINGS', 'MMA'],
            productTerm: [],
            zip: zipCode,
          }),
      },
    ]
      .filter(({ id }) => ids.indexOf(id) > -1)
      .map(({ req, id }) =>
        req()
          .then((res) => ({
            id,
            replaceWithSavings: res.length < 1 && id === RedVentureWidgets.CD_RATES,
            success: true,
          }))
          .catch(() => {
            trackEvent({
              contentPlacementCD: 'Recommends Widget',
              eventAction: 'API Error',
              eventCategory: 'Recommends Widget',
              ...trackingData,
            });
            return { id, success: false };
          })),
  );

const replaceCDWidgetCheck = (res: { id: RedVentureWidgets; replaceWithSavings?: boolean }[], wConfigId: string) =>
  res.some(({ id, replaceWithSavings }) => id === wConfigId && replaceWithSavings === true);

const ContentWithRVWidgets = ({
  children,
  widgetIds = [],
  microsite,
  zipCode,
  trackingData,
  postId,
}: {
  children: ReactElement;
  widgetIds: string[];
  microsite: string;
  zipCode?: string;
  trackingData: EventData;
  postId: number;
}) => {
  const [widgetsConfig, setWidgetsConfig] = useState(RVWidgetsConfig[microsite]);
  const anyEnabled = widgetsConfig && widgetsConfig.some((widget) => widget.enabled);
  const savingsWidgetConfig = widgetsConfig?.[0];

  useEffect(() => {
    testRVAPIs(zipCode || defaultZipCode, widgetIds, trackingData)
      .then((res) => {
        setWidgetsConfig(
          widgetsConfig.map((wConfig) => {
            const checkReplaceCD = replaceCDWidgetCheck(res, wConfig.id);
            const finalConfig = checkReplaceCD ? savingsWidgetConfig : wConfig;
            return {
              ...finalConfig,
              id: wConfig.id,
              show: res.some(({ id, success }) => id === wConfig.id && success),
            };
          }),
        );
      })
      .catch(() => {
        setWidgetsConfig([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetIds]);

  return anyEnabled ? embedRVWidgets(children, widgetsConfig, zipCode as string, trackingData, postId) : children;
};

export default ContentWithRVWidgets;
